import React from "react";
import { presence, titleize } from "helpers";
import { Button } from "antd";
import { allCardstatus } from "config";

export const columns = (openEditForm) => {
  return [
    {
      title: "Card Number",
      dataIndex: "card_id",
      align: "center",
      render: (card_id) => `${presence(card_id)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => `${titleize(status)}`,
      filters:
        allCardstatus &&
        allCardstatus.map((type) => {
          return { text: titleize(type), value: type };
        }),
    },
    {
      title: "Name",
      dataIndex: "current_assignee",
      align: "center",
      render: (current_assignee) => {
        let aa = `${titleize(
          current_assignee.visitor ? current_assignee.visitor.name : ""
        )}`;
        return `${presence(aa)}`;
      },
    },
    {
      title: "Phone",
      dataIndex: "current_assignee",
      align: "center",
      render: (current_assignee) => {
        let bb = `${titleize(
          current_assignee.visitor ? current_assignee.visitor.phone : ""
        )}`;
        return `${presence(bb)}`;
      },
    },
    {
      title: "Actions",
      render: (row) => {
        return <Button onClick={() => openEditForm(row)}>Edit</Button>;
      },
      align: "center",
    },
  ];
};
