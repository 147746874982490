import React, { Component } from "react";
import Form from "./Form";
import { createContractorCard, updateContractorCard } from "api/contractorcard";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        card_id: "",
        status: "available",
        visitor_type : this?.props?.type
      },
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      ...data,
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = id ? updateContractorCard : createContractorCard;
    method({ contractor_cards: this.requestParams() }, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, card_id, status } = this.props.data;
      this.id = id;

      this.setState({
        data: {
          ...this.state.data,
          card_id,
          status,
        },
      });
    }
  };

  render() {
    const { id, handleChange } = this;
    const { data } = this.state;
    const formProps = { id, ...data, handleChange };
    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}
export default DialogForm()(CRUD);
