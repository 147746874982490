import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let visitor_types = "";
  let names = "";
  let employers = "";
  let phone_no = "";
  let vehicle_types = "";
  let alertLink = "";
  let device_names = "";

  const {
    visitor_type,
    phone,
    name,
    employer,
    vehicle_type,
    other_filter,
    device_name,
  } = params;
  if (visitor_type) {
    visitor_types = visitor_type
      .map((value) => `&visitor_type[]=${value}`)
      .join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }
  if (name && name.length > 0) {
    names = name.map((key) => `&name[]=${key.label}`).join("");
  }
  if (employer && employer.length > 0) {
    employers = employer.map((key) => `&employer[]=${key.label}`).join("");
  }
  if (vehicle_type && vehicle_type.length > 0) {
    vehicle_types = vehicle_type
      .map((key) => `&vehicle_type[]=${key}`)
      .join("");
  }
  if (device_name && device_name.length > 0) {
    device_names = device_name
      .map((key) => `&device_name[]=${key.label}`)
      .join("");
  }

  if (other_filter) alertLink = `&alert_linked=${other_filter}`;
  else alertLink = `&alert_linked=${false}`;

  return `from_time=${params.fromTime}&to_time=${params.toTime
    }${visitor_types}${phone_no}${names}${employers}${vehicle_types}${alertLink}${device_names}&query=${params.search
    }&page=${params.page || 1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/visits?${generateParams(requestParams)}`,
  });
}

export function aggregation(params) {
  return makeRequest({
    uri: `/api/v1/visits/aggs?from_time=${params.fromTime}&to_time=${params.toTime}&visitor_type[]=${params.purpose}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/visits/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}

export function checkoutData(id, requestParams) {
  return makeRequest({
    uri: `/api/v1/visits/${id}/exit`,
    method: "PUT",
    body: JSON.stringify(requestParams)
  });
}
