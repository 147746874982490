import React, { Component } from "react";
import Form from "./Form";
import { updateKiosk, createKiosk } from "api/kiosk";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.timer = null;
    this.state = {
      data: {
        name: "",
        mac_address: "",
        device_type: this.props.data?.device_type || "vms",
        status: "active",
        bots_data: [],
        unassignedBot: [],
        selected_bots: "",
        timeout: "180",
        description: "",
      },
      open: false,
    };
  }

  componentDidMount = () => {
    if (this.props.data) {
      const {
        id,
        name,
        device_type,
        mac_address,
        type,
        status,
        timeout,
        description,
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          device_type,
          name,
          mac_address,
          type,
          status,
          timeout,
          description,
        },
        id,
      });
    }
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  tabChange = (e) => {
    this.setState({
      device_type: e.target.value,
      name: "",
      mac_address: "",
      status: "",
      selected_bots: "",
      timeout: "",
      description: "",
    });
    this.setState({ [e.target.name]: e.target.value });
  };

  requestParams = () => {
    const {
      data: { selected_bots, ...rest },
    } = this.state;
    const { id } = this;

    return {
      kiosks: {
        id,
        ...rest,
        bot_id: selected_bots?.value,
      },
    };
  };

  onSubmit = () => {
    const { id, requestParams, props } = this;
    let requestValue = requestParams();
    const action = id ? updateKiosk : createKiosk;
    action(requestValue, id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { device } = this.props;
    const {
      id,
      handleChange,
      tabChange,
    } = this;
    const { data } = this.state;
    const props = {
      id,
      data,
      device,
      handleChange,
      tabChange,
    };
    return (
      <>
        <Form {...props} />
      </>
    );
  }
}
export default DialogForm("Kiosk", 620)(CRUD);
