import React from "react";
import { presence, titleize } from "helpers";
import { Button } from "antd";

export const StatusComp = ({ value }) => {
  const colors = {
    Inactive: "#ff5252",
    Active: "#4caf50",
  };
  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};

export const columns = (openEditForm) => {
  return [
    {
      title: "Company",
      dataIndex: "name",
      align: "center",
      render: (name) => `${presence(name)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <StatusComp value={titleize(status)} />,
    },
    {
      title: "Block",
      dataIndex: "block",
      align: "center",
      render: (block) => `${presence(block)}`,
    },
    {
      title: "Actions",
      render: (row) => {
        return <Button onClick={() => openEditForm(row)}>Edit</Button>;
      },
      align: "center",
    },
  ];
};
