import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let cardStatus = "";
  let search = ""
  let page = ""
  let type = ""
  if (params.status) {
    cardStatus = params.status.map((value) => `&status[]=${value}`).join("");
  }

  if (params.search) {
    search = `&query=${params.search}`;
  }

  if (params.page || params.perPage) {
    page = `&page=${params.page ||
      1}&per_page=${totalEntries || params.perPage}`;
  }

  if (params.type) {
    type = `visitor_type=${params.type}`;
  }

  return `${type}${cardStatus}${search}${page}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/contractor_cards?${generateParams(requestParams)}`,
  });
}
export function createContractorCard(requestBody) {
  return makeRequest({
    uri: `/api/v1/contractor_cards`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateContractorCard(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/contractor_cards/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function uploadBulkContractorCard(body) {
  return makeRequest({
    uri: `/api/v1/contractor_cards/import`,
    method: "POST",
    body,
  });
}
