import React from "react";
import { formatTime, timeDiff, presence } from "helpers";
import { apiHost } from "config";
import { Tooltip } from "antd";
import moment from "moment";
import defaultImage from "assets/img/default_image.jpg";

const columns = [
  {
    title: "Media / Name",
    dataIndex: "media_path",
    render: (_, row) => {
      let video = row.video_path && (
        <p>
          <img
            src={`https://res.cloudinary.com/katomarantbs/image/upload/v1593675406/general/video_tmsmuo.png`}
            alt=""
            style={{ maxWidth: "7.2vw" }}
          />
        </p>
      );
      const url_img = row?.s3_image_path?.startsWith("http")
        ? row?.s3_image_path
        : `${apiHost}/${row?.s3_image_path}`;
      const path_img = row?.image_path?.startsWith("http") ? row?.image_path : `${apiHost}/${row?.image_path}`

      let image = row?.s3_image_path && (
        <p>
          <img
            src={url_img || defaultImage}
            alt="Alert Pic"
            style={{ maxWidth: "7.2vw" }}
          />
        </p>
      );

      return (
        (presence(path_img) && image) ||
        (row.video_path && video) ||
        (row.entity && row.entity.name) || (
          <Tooltip
            title={
              row.entity &&
              row.entity.current_visitor &&
              row.entity.current_visitor.phone
            }
          >
            {(row.entity && row.entity.number_plate) || "-"}
          </Tooltip>
        )
      );
    },
    align: "center",
  },
  {
    title: "Triggered At",
    dataIndex: "from_time",
    render: (_, row) => {
      return (
        <div>
          <p>{formatTime(row.from_time)}</p>

          {row.status !== "Resolved" && row.from_time && (
            <p> {timeDiff(moment(), row.from_time)}</p>
          )}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (_, row) => <StatusComp row={row} />,
  },
];

const raised = (status) => status === "Raised";
const resolved = (status) => status === "Resolved";

const StatusComp = ({ row: { status, from_time, to_time } }) => {
  const bgColor = raised(status)
    ? "#e50027"
    : resolved(status)
      ? "#348302"
      : "#fca504";
  const tooltip = status === "Resolved" ? timeDiff(from_time, to_time) : "";

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          margin: "0px 0px 10px 0px",
          width: 90,
          height: 36,
          borderRadius: "30px",
          backgroundColor: bgColor,
          display: "flex",
        }}
      >
        <p
          style={{
            fontFamily: "Calibri",
            fontSize: "1em",
            color: "#fff",
            margin: "auto",
          }}
        >
          <Tooltip title={tooltip ? `in ${tooltip}` : ""}> {status}</Tooltip>
        </p>
      </span>
    </div>
  );
};

export default columns;
