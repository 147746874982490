import React from "react";
// import { Input } from "@material-ui/core/index";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton.jsx";
import { vizitinType } from "config";
export default function Form(props) {
  const {
    id,
    data,
    handleChange,
  } = props;
  const deviceStatus = ["active", "inactive"];
  const { name, mac_address, status, device_type, timeout } = data;

  const timeOutStyle = {
    padding: "10px 20px",
    marginTop: "10px",
  };

  return (
    <React.Fragment>
      <Row style={{ padding: 10 }}>
        <Col xs={24} sm={12}>
          <Row>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="Name"
                name="name"
                value={name}
                onChange={handleChange}
                disabled={id}
                required
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="MAC Address"
                name="mac_address"
                value={mac_address}
                onChange={handleChange}
                required
                disabled={id}
              />
            </Col>
            {!id && (
              <Col xs={24} sm={24} style={{ padding: "10px 17px" }}>
                <RadioButton
                  labelText="Status"
                  value={status || "active"}
                  data={deviceStatus}
                  onChange={handleChange}
                  name="status"
                  color={true}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row>
            {!id && vizitinType && device_type && (
              <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">Device Type</InputLabel>
                  <Select
                    style={{ width: "100%", height: "84%" }}
                    name="device_type"
                    value={device_type}
                    onChange={handleChange}
                    required
                    disabled={id}
                  >
                    {vizitinType.map((val, i) => {
                      return (
                        <MenuItem key={i} value={val.value}>
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              style={!id ? timeOutStyle : { padding: "10px 20px" }}
            >
              <SimpleTextInput
                labelText="TimeOut (in minutes)"
                name="timeout"
                value={timeout}
                onChange={handleChange}
                required
              />
            </Col>
            {id && (
              <Col
                xs={24}
                sm={24}
                style={!id ? timeOutStyle : { padding: "10px 20px" }}
              >
                <RadioButton
                  labelText="Status"
                  value={status || "active"}
                  data={deviceStatus}
                  onChange={handleChange}
                  name="status"
                  color={true}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}
