import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";

function Form(props) {
  const { handleChange, block, status, name, id } = props;
  const contractType = {
    labelText: "Status",
    value: status,
    data: ["active", "inactive"],
    onChange: handleChange,
    name: "status",
    width: "32%",
    color: true
  };
  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Company Name"
            name="name"
            value={name}
            required
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Block"
            name="block"
            value={block}
            onChange={handleChange}
            readOnly={!!id}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          style={{ padding: "10px 0px" }}
        >
          <RadioButton {...contractType} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;
