import React from "react";
import CustomButton from "components/CustomButton";
import { CardHeader } from "material-dashboard-react/components";
import {
  MdArrowDownward,
  MdArrowDropDown,
  MdCached,
  MdHourglassEmpty,
  MdAdd,
} from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { GiExitDoor } from "react-icons/gi";

export default function TableHeader(props) {
  const {
    tabColor,
    title,
    subTitle,
    triggerRefresh,
    generateReport,
    handleOnClick,
    OnClickUpload,
    dataValue,
    normalIcon,
    dataValueUpload,
    createAction,
    uploadAction,
    multiResolve,
    triggerResolve
  } = props;
  return (
    <CardHeader
      color={tabColor}
      style={{
        padding: 15,
        borderRadius: 3,
        width: "98%",
        marginTop: -20,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>
        <h4
          style={{
            margin: "2px 0px 5px",
            fontSize: "1.3em",
            fontWeight: 300,
            color: "#fff",
          }}
        >
          {title}
        </h4>
        <p
          style={{
            margin: 0,
            fontSize: "14px",
            color: "rgba(255,255,255,.62)",
          }}
        >
          {subTitle}
        </p>
      </span>
      <div>
        {multiResolve && (
          <CustomButton
            color={tabColor}
            normalIcon={<GiExitDoor style={{ fontSize: "25px" }} />}
            onClickIcon={<GiExitDoor style={{ fontSize: "25px" }} />}
            onClick={triggerResolve}
            dataValue="checkout"
            progressStyles={{
              top: 9,
              right: 85,
            }}
          />
        )}
        {triggerRefresh && (
          <CustomButton
            color={tabColor}
            normalIcon={<MdCached style={{ fontSize: "25px" }} />}
            onClickIcon={<MdHourglassEmpty style={{ fontSize: "25px" }} />}
            onClick={triggerRefresh}
            loader
            dataValue="refresh"
            progressStyles={{
              top: 9,
              right: 85,
            }}
          />
        )}
        {generateReport ? (
          <CustomButton
            color={tabColor}
            normalIcon={<MdArrowDownward style={{ fontSize: "25px" }} />}
            onClickIcon={<MdArrowDropDown style={{ fontSize: "25px" }} />}
            onClick={generateReport}
            loader
            dataValue="download"
            progressStyles={{
              top: 9,
              right: 19,
            }}
          />
        ) : (
          false
        )}
        {dataValue && createAction && (
          <CustomButton
            color={tabColor}
            normalIcon={
              normalIcon ? (
                <BsUpload style={{ fontSize: "25px" }} />
              ) : (
                <MdAdd style={{ fontSize: "25px" }} />
              )
            }
            onClick={handleOnClick}
            dataValue={dataValue}
            progressStyles={{
              top: 9,
              right: 85,
            }}
          />
        )}
        {dataValueUpload && uploadAction && (
          <CustomButton
            color={tabColor}
            normalIcon={<BsUpload style={{ fontSize: "25px" }} />}
            onClick={OnClickUpload}
            dataValue={dataValueUpload}
            progressStyles={{
              top: 9,
              right: 19,
            }}
          />
        )}
      </div>
    </CardHeader>
  );
}
