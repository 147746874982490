import React from "react";
import { presence } from "helpers";
import { Button } from "antd";

const Status = ({ value }) => {
  const colors = {
    active: "#4caf50",
    inactive: "#ff5252",
  };

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};

export const columns = (editRegisteredCard) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      render: (name) => `${presence(name)}`,
    },

    {
      title: "Employer",
      dataIndex: "employer",
      align: "center",
      render: (employer) => `${presence(employer)}`,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
      render: (phone) => `${presence(phone)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <Status value={status} />,
    },
    {
      title: "Manage",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            <div>
              <Button
                onClick={() => {
                  editRegisteredCard(row);
                }}
                size="medium"
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>
            </div>
          </React.Fragment>
        );
      },
    }
  ];
};
