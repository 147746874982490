import React from "react";
import { Col } from "antd";
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Input,
  styled,
  InputBase,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { phoneLimit } from "helpers";
import { countryCode } from "config";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function SimplePhoneInput(props) {
  let {
    classes,
    labelText,
    name,
    id,
    required,
    value,
    type,
    onChange,
    readOnly,
    disabled,
    multi,
    rows,
    width,
    time,
    length,
    countryValues,
  } = props;
  if (required) {
    labelText = `${labelText} *`;
  }
  const underlineClasses = classNames({
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });

  return (
    <>
      <Col xs={12} sm={12} md={12} style={{ padding: "20px 10px 0px" }}>
        <Select
          style={{ width: "100%", height: "84%" }}
          value={countryValues}
          onChange={onChange}
          input={<BootstrapInput />}
          name="country_code"
        >
          {countryCode.map((val, i) => {
            return (
              <MenuItem key={i} value={val.value}>
                {val.label}
              </MenuItem>
            );
          })}
        </Select>
      </Col>
      <Col xs={12} sm={12} md={12} style={{ padding: "10px" }}>
        <FormControl
          fullWidth
          className={classes.formControl}
          style={{
            margin: 0,
            width: width ? width : "100%",
            opacity: time && "0",
          }}
        >
          {labelText !== undefined && (
            <InputLabel className={classes.labelRoot} htmlFor={id}>
              {labelText}
            </InputLabel>
          )}
          <Input
            classes={{
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            className={time && "facilitytime"}
            multiline={multi}
            rows={multi ? rows : 0}
            name={name}
            id={id || name}
            required={required}
            value={value?.replace(/[^0-9 ]/g, "")}
            onChange={onChange}
            type={type}
            readOnly={readOnly}
            disabled={disabled}
            inputProps={{
              maxLength: phoneLimit(length),
            }}
          />
        </FormControl>
      </Col>
    </>
  );
}

SimplePhoneInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  rows: PropTypes.number,
};

SimplePhoneInput.defaultProps = {
  type: "text",
  readOnly: false,
  disabled: false,
  multi: false,
  rows: 3,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      border: "2px solid  purple",
    },
  },
}));

export default withStyles(customInputStyle)(SimplePhoneInput);
