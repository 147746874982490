import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let search = ""
  let page = ""

  if (params.search) {
    search = `&query=${params.search}`;
  }

  if (params.page || params.perPage) {
    page = `&page=${params.page ||
      1}&per_page=${totalEntries || params.perPage}`;
  }

  return `${search}${page}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/companies?${generateParams(requestParams)}`,
  });
}
export function createCompany(requestBody) {
  return makeRequest({
    uri: `/api/v1/companies`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateCompany(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/companies/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function uploadBulkCompany(body) {
  return makeRequest({
    uri: `/api/v1/companies/import`,
    method: "POST",
    body,
  });
}
