import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  MdAccessTime,
  MdCamera,
  MdPermIdentity,
  MdWeekend,
  MdLocationCity,
  MdPerson,
} from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { apiHost, showImgtimeline } from "config";
import { VehicleEntriesContext } from "context/ContextProvider";
import { getCookie, titleize, presence, formatTime, timeDiff } from "helpers";
import { Col } from "antd";
import Dialog from "@material-ui/core/Dialog";
import { updateOcrValue } from "api/aboutVehicleEntries";
import { handleResponse } from "components/notifications/handleResponse";
import { MdDone, MdClose, MdBlock } from "react-icons/md";
import { IoMdAlert } from "react-icons/io";
import { FaAddressCard, FaTabletAlt } from "react-icons/fa";

const styles = {
  topLabel: {
    fontSize: 16,
    position: "absolute",
    top: -28,
    left: 0,
    width: "100%",
    padding: "10px 25px",
    backgroundColor: "#1dbace",
  },
  labelTitle: {
    margin: 0,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
};

const Timeline = ({ color, icon, body }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      iconStyle={{
        background: color,
        color: "#fff",
      }}
      icon={icon}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {body}
      </div>
    </VerticalTimelineElement>
  );
};
const AlertTimeline = ({ icon, body, statColr }) => {
  return (
    <VerticalTimelineElement
      className={`vertical-timeline-element--work ${statColr}`}
      iconStyle={{
        background: statColr === "raised" ? "#e53935" : "#43a047",
        color: "#fff",
      }}
      icon={icon}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {body}
      </div>
    </VerticalTimelineElement>
  );
};

const renderTitle = (text) => (
  <h4
    style={{
      margin: 0,
      fontWeight: 100,
      display: "flex",
      alignItems: "center",
    }}
  >
    {text}
  </h4>
);

const AlertrenderTitle = (text) => (
  <h4
    style={{
      margin: 0,
      fontWeight: 100,
      display: "flex",
      alignItems: "center",
      color: "#fff",
    }}
  >
    {text}
  </h4>
);

const NormalCard = ({ title, data }) => {
  return (
    <React.Fragment>
      {renderTitle(title)}
      <Col>
        <span
          style={{
            margin: "5px 0px",
            textAlign: "center",
          }}
        >
          <span>
            <h2 style={{ margin: 0, overflowWrap: "anywhere" }}>
              {presence(data)}
            </h2>
          </span>
        </span>
      </Col>
    </React.Fragment>
  );
};

const ImageCard = ({ title, text, duration, numberPlateImage, fullImgURL }) => {
  let ocr = numberPlateImage && numberPlateImage.ocr;
  let id = numberPlateImage && numberPlateImage.id;
  let imgURL = numberPlateImage && numberPlateImage.relative_path;

  const [open, setOpen] = React.useState(false);
  const [ocrValue, setOcrValue] = React.useState(ocr);

  const handleOcrClick = (id, value) => {
    updateOcrValue(id, { ocr: !value })
      .then((data) => {
        setOcrValue(data.data.ocr);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  let btnStyles = {
    fontSize: 35,
    border: "1px solid",
    borderRadius: "50%",
    padding: 4,
    cursor: "pointer",
  };
  return (
    <React.Fragment>
      {getCookie("role") === "Super Admin" && (
        <div
          style={{
            width: 50,
            marginRight: 10,
            borderRight: "1px solid lightgray",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {ocrValue ? (
              <MdDone
                style={{
                  ...btnStyles,
                  color: "green",
                }}
                onClick={() => handleOcrClick(id, ocrValue)}
              />
            ) : (
              <MdClose
                style={{
                  ...btnStyles,
                  color: "red",
                }}
                onClick={() => handleOcrClick(id, ocrValue)}
              />
            )}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {renderTitle(title)}
        <Col style={{ textAlign: "center" }}>
          <h2>{presence(text)}</h2>
          {showImgtimeline && imgURL && (
            <>
              {imgURL.startsWith("https") ? (
                <img src={imgURL} alt="Number Plate" style={{ width: "75%" }} />
              ) : (
                <img
                  alt={"Number Plate"}
                  style={{ width: "75%" }}
                  src={`${apiHost}${imgURL}`}
                />
              )}
            </>
          )}
          {fullImgURL && (
            <React.Fragment>
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
                href="#popup"
                onClick={() => setOpen(true)}
              >
                click to view full image
              </a>
              <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
                <>
                  {fullImgURL.startsWith("https") ? (
                    <img
                      src={fullImgURL}
                      style={{ maxWidth: "100%" }}
                      alt="Full Car Pic"
                    />
                  ) : (
                    <img
                      alt={"Full Car Pic"}
                      style={{ maxWidth: "100%" }}
                      src={`${apiHost}${fullImgURL}`}
                    />
                  )}
                </>
              </Dialog>
            </React.Fragment>
          )}
          {duration && <p style={{ fontSize: 14 }}>( {duration} )</p>}
        </Col>
      </div>
    </React.Fragment>
  );
};

const renderTR = (name, value) => {
  let unit_num = value?.split(",");
  return (
    <tr>
      <td style={{ textAlign: "right" }}>
        <h3>{name}</h3>
      </td>
      <td style={{ minWidth: 40, textAlign: "center", paddingBottom: 10 }}>
        -
      </td>
      <td>
        {unit_num?.map((number, i) => (
          <h2 key={i} style={{ fontSize: "1.2rem", overflowWrap: "anywhere" }}>
            {number}
          </h2>
        ))}
      </td>
    </tr>
  );
};

const GroupContentCard = ({ title, data }) => {
  return (
    <React.Fragment>
      <div style={styles.topLabel}>
        <h4 style={styles.labelTitle}>{title}</h4>
      </div>
      <Col style={{ textAlign: "center" }}>
        <span style={{ display: "table" }}>
          <table>
            <tbody style={{ textAlign: "left" }}>
              {Object.keys(data).map(
                (key) => data[key] && renderTR(key, data[key])
              )}
            </tbody>
          </table>
        </span>
      </Col>
    </React.Fragment>
  );
};
const AlertContentCard = ({ title, type_of_alert, TriggeredAt }) => {
  return (
    <React.Fragment>
      {AlertrenderTitle(title)}
      <Col style={{ textAlign: "center" }}>
        <span style={{ display: "table" }}>
          <table>
            <tbody style={{ textAlign: "center" }}>
              <p style={{ fontSize: 20, color: "#fff", margin: 0 }}>
                {type_of_alert}
              </p>
              <p style={{ fontSize: "14px", color: "#fff" }}>{TriggeredAt}</p>
            </tbody>
          </table>
        </span>
      </Col>
    </React.Fragment>
  );
};
class Main extends Component {
  statusValue = (status, visitStatus) => {
    const statusValue = [
      "in",
      "expired",
      "expired_and_out",
      "out",
      "direct_out",
      "neglected",
      "partially_out",
    ];
    let vehicleStatus = statusValue.forEach((vehicleStatus, index) => {
      if (vehicleStatus === status) {
        return index;
      }
    });
    let visitorStatus = statusValue.forEach((vehicleStatus, index) => {
      if (vehicleStatus === visitStatus) {
        return index;
      }
    });
    return vehicleStatus >= visitorStatus ? status : visitStatus;
  };

  renderCameraCard = (
    {
      camera: { name, id: camera_id },
      detected_time,
      number_plate_image,
      full_vehicle_image,
    },
    index,
    vehicle_trackings
  ) => {
    let prev;
    let curr;
    const vts = vehicle_trackings.filter((obj) => !!obj.camera);
    const vt_index = vts.findIndex((obj) => {
      return camera_id === obj.camera.id;
    });
    if (vts.length > 1 && vt_index > 0) {
      curr = detected_time;
      prev = vts[vt_index - 1].detected_time;
    }

    let time = formatTime(detected_time, "MMMM Do YYYY, hh:mm:ss a");
    return (
      <Timeline
        key={index}
        color="#0bb3c8"
        icon={<MdCamera />}
        body={
          <ImageCard
            title={name}
            text={time}
            duration={prev && timeDiff(prev, curr)}
            numberPlateImage={number_plate_image}
            fullImgURL={full_vehicle_image && full_vehicle_image.relative_path}
          />
        }
      />
    );
  };

  render() {
    const statusStyle = {
      in: { text: "In", backgroundColor: "#18A2E6" },
      expired: { text: "Expired", backgroundColor: "#ed420f" },
      expired_and_out: { text: "Expired and Out", backgroundColor: "#c0a835" },
      out: { text: "Out", backgroundColor: "#0cba0c" },
      direct_out: { text: "Direct Out", backgroundColor: "#000000" },
      neglected: { text: "Neglected", backgroundColor: "#e9464e" },
      partially_out: { text: "Out", backgroundColor: "#0cba0c" },
    };
    return (
      <VerticalTimeline>
        <VehicleEntriesContext.Consumer>
          {({
            data: {
              alerts,
              in_time,
              out_time,
              status,
              vehicle_trackings,
              visit,
              purpose_of_visit,
            },
          }) => {
            const {
              kiosk,
              staff,
              visitor,
              invite_visitor,
              sub_visitor_type,
              contractor_card,
            } = visit || {};

            const renderTypeTitle = (type) => {
              type = type.replace(/\./g, " ");
              type = type.replace(/_/g, "");
              type = type.replace(/\w\S*/g, function(txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });
              return type;
            };

            return (
              <React.Fragment>
                {staff && (
                  <Timeline
                    color="#d526c9"
                    icon={<MdPermIdentity />}
                    body={
                      <NormalCard
                        title={"Invited by"}
                        data={
                          staff.full_name
                            ? `${staff.full_name} / ${staff.phone}`
                            : `${staff.name} / ${staff.phone}`
                        }
                      />
                    }
                  />
                )}
                {invite_visitor && (
                  <Timeline
                    color="#0cc693"
                    icon={<MdWeekend />}
                    body={
                      <NormalCard
                        title={"Visiting"}
                        data={`${invite_visitor.block} / ${invite_visitor.visiting_time} / ${invite_visitor.purpose_of_visit} `}
                      />
                    }
                  />
                )}
                {kiosk?.name && (
                  <Timeline
                    color="rgb(188, 98, 89)"
                    icon={<FaTabletAlt style={{ fontSize: 14 }} />}
                    body={<NormalCard title={"Device"} data={kiosk.name} />}
                  />
                )}
                {alerts &&
                  alerts.map(
                    (blocked) =>
                      blocked.alert_type === "blacklist" && (
                        <Timeline
                          color="rgb(0,0, 0)"
                          icon={<MdBlock style={{ fontSize: 14 }} />}
                          body={
                            <NormalCard
                              title={"Black Listed"}
                              data={titleize(blocked.alert_type)}
                            />
                          }
                        />
                      )
                  )}
                {contractor_card && contractor_card.card_id && (
                  <Timeline
                    color="rgb(76,0,153)"
                    icon={<FaAddressCard style={{ fontSize: 14 }} />}
                    body={
                      <NormalCard
                        title={"Contractor Card Number"}
                        data={contractor_card.card_id}
                      />
                    }
                  />
                )}
                {/* {visitor_detail && (
                  <Timeline
                    color="#ff407e"
                    icon={<MdPerson />}
                    body={
                      <GroupContentCard
                        title={"Visitor Details"}
                        data={{
                          Name: name,
                          "NRIC/FIN": fin_number,
                          Phone: phone,
                          Employer: employer
                        }}
                      />
                    }
                  />
                )} */}
                {visitor && (
                  // visitor_details.map(vd => {
                  //   return (
                  <Timeline
                    color="#ff407e"
                    icon={<MdPerson />}
                    body={
                      <GroupContentCard
                        title={"Visitor Details"}
                        data={
                          visitor.fin_number
                            ? {
                                Name: visitor.name,
                                "NRIC/FIN": visitor.fin_number,
                                Phone: `${presence(visitor.country_code)} ${visitor.phone}`,
                                Employer: visitor.employer,
                                EntryMessage: visitor.entry_msg,
                                ExitMessage: visitor.exit_msg,
                              }
                            : {
                                Name: visitor.name,
                                "Identity Number": visitor.card_number,
                                Phone: `${presence(visitor.country_code)} ${visitor.phone}`,
                                Employer: visitor.employer,
                                EntryMessage: visitor.entry_msg,
                                ExitMessage: visitor.exit_msg,
                              }
                        }
                      />
                    }
                  />
                )
                // );
                }
                {/* )} */}
                {visit && (
                  <Timeline
                    color="#2e26a7"
                    icon={<MdLocationCity />}
                    body={
                      <GroupContentCard
                        title={"Entry Details"}
                        data={{
                          "Visitor Type": titleize(purpose_of_visit),
                          Type: sub_visitor_type,
                          "Entry at": formatTime(
                            in_time,
                            "MMMM Do YYYY, hh:mm:ss a"
                          ),
                          "To meet": staff ? staff.name : null,
                          "To visit": staff ? staff.department : null,
                        }}
                      />
                    }
                  />
                )}
                {vehicle_trackings &&
                  vehicle_trackings
                    .concat(alerts)
                    .sort(
                      (a, b) =>
                        new Date(a.detected_time || a.from_time) -
                        new Date(b.detected_time || b.from_time)
                    )
                    .map((result, index) => {
                      return Object.keys(result).includes("camera") ? (
                        this.renderCameraCard(result, index, vehicle_trackings)
                      ) : (
                        <AlertTimeline
                          color="#e53935"
                          icon={<IoMdAlert />}
                          body={
                            <AlertContentCard
                              title={"Alerts"}
                              type_of_alert={renderTypeTitle(result.type)}
                              TriggeredAt={formatTime(
                                result.from_time,
                                "MMMM Do YYYY, hh:mm:ss a"
                              )}
                            />
                          }
                          statColr={result.status}
                        />
                      );
                    })}
                {status && (
                  <Timeline
                    color={statusStyle[status]["backgroundColor"]}
                    icon={<FaCheck style={{ fontSize: 22 }} />}
                    body={
                      <NormalCard
                        title={"Status"}
                        data={
                          statusStyle[
                            visit && visit.status
                              ? this.statusValue(
                                  status,
                                  visit.status && visit.status
                                )
                              : status
                          ]["text"]
                        }
                      />
                    }
                  />
                )}
                {in_time && out_time && (
                  <Timeline
                    color="#9b32dd"
                    icon={<MdAccessTime />}
                    body={
                      <NormalCard
                        data={timeDiff(in_time, out_time)}
                        title={"Duration"}
                      />
                    }
                  />
                )}
              </React.Fragment>
            );
          }}
        </VehicleEntriesContext.Consumer>
      </VerticalTimeline>
    );
  }
}
export default Main;
