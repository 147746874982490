import React from "react";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton";
import Users from "./user";
import Update from "./update";

const Form = ({
  first_name,
  last_name,
  email,
  phone,
  userRoles,
  botRoles,
  rolesData,
  id,
  send_welcome,
  handleChange,
  status,
  rolesBotData,
  type,
  auth_token,
  country_code,
  read_only,
  rolesRequired,
}) => {
  const tabProps = {
    first_name,
    last_name,
    email,
    phone,
    userRoles,
    type,
    botRoles,
    rolesData,
    rolesBotData,
    handleChange,
    id,
    auth_token,
    country_code,
    read_only,
    rolesRequired,
  };
  return (
    <React.Fragment>
      <Row
        spacing={3}
        style={{ padding: 10, minWidth: 500, minHeight: id ? 265 : 325 }}
      >
        {!id && <Users {...tabProps} />}
        {id && <Update {...tabProps} />}
        <Col xs={24} sm={12} style={{ padding: 10, zIndex: 0 }}>
          <RadioButton
            labelText="Status"
            value={status}
            data={{ Active: "active", Inactive: "inactive" }}
            onChange={handleChange}
            name="status"
            width="40%"
          />
        </Col>
        {!id && type === "User" && (
          <Col xs={24} sm={12} style={{ padding: 10, zIndex: 0 }}>
            <RadioButton
              labelText="Send Welcome Mail"
              value={send_welcome}
              data={{ Yes: true, No: false }}
              onChange={handleChange}
              name="send_welcome"
              width="40%"
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Form;
