import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

class PaginationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ loading: true });
    this.props.handleTableChange(pagination, filters, sorter);
  };

  render() {
    const {
      tabColor,
      title,
      subTitle,
      triggerRefresh,
      generateReport,
      columns,
      data,
      pagination,
      onRowClick,
      downloadArrow,
      screenUser,
      skeleton,
      refershloader,
      loader,
      multiResolve,
      triggerResolve,
      multiResolveCall,
      selectedRowKeys
    } = this.props;
    const headerprops = {
      tabColor,
      title,
      subTitle,
      triggerRefresh,
      downloadArrow,
      generateReport,
      screenUser,
      refershloader,
      multiResolve,
      triggerResolve
    };
    const { loading } = this.state;

    const { handleTableChange } = this;
    const bodyprops = {
      columns,
      data,
      pagination,
      handleTableChange,
      onRowClick,
      skeleton,
      loading,
      loader,
      multiResolveCall,
      selectedRowKeys
    };
    return (
      <Paper className="paperClass" style={{ zIndex: "0" }}>
        <TableHeader {...headerprops} />
        <TableBody {...bodyprops} />
      </Paper>
    );
  }
}

export default PaginationTable;
