import React from "react";
import { Col } from "antd";
import MultiSelect from "components/inputs/Multiselect";
import SimpleTextInput from "components/inputs/simpleTextInput";
import SimplePhoneInput from "components/inputs/simplePhoneInput";

const Update = ({
  first_name,
  last_name,
  email,
  phone,
  userRoles,
  botRoles,
  rolesData,
  handleChange,
  rolesBotData,
  type,
  country_code,
  auth_token,
  read_only,
  password,
}) => {
  let roles_value = localStorage.getItem("roles");
  let removeRole = "Super Admin";
  let filtered_roles = rolesData?.filter((data) => data.label !== removeRole);
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="First Name"
          name="first_name"
          required
          value={first_name}
          onChange={handleChange}
          readOnly={read_only}
        />
      </Col>
      <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Last Name"
          name="last_name"
          value={last_name}
          onChange={handleChange}
        />
      </Col>
      {type === "Bot" && (
        <Col item xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            readOnly
            labelText="Auth Token"
            name="auth_token"
            value={auth_token}
          />
        </Col>
      )}
      {type === "User" && (
        <React.Fragment>
          <SimplePhoneInput
            countryValues={country_code}
            onChange={handleChange}
            labelText="Phone"
            name="phone"
            value={phone}
            length={country_code}
            required
          />
          <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </Col>
          <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Password"
              name="password"
              type="password"
              value={password}
              onChange={handleChange}
            />
          </Col>
        </React.Fragment>
      )}
      <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
        <MultiSelect
          single={true}
          isMulti={false}
          heading={"Roles"}
          selected={type === "User" ? userRoles : botRoles}
          options={
            type === "User"
              ? roles_value.includes("Super Admin")
                ? rolesData
                : filtered_roles
              : rolesBotData
          }
          handleChange={handleChange}
          name={type === "User" ? "userRoles" : "botRoles"}
        />
      </Col>
    </React.Fragment>
  );
};
export default Update;
