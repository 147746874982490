import React, { Component } from "react";
import { Row, Col } from "antd";
import { titleize } from "helpers";
import moment from "moment";
import { IoMdOpen } from "react-icons/io";
import VehicleDetails from "./vehicleDetails";

const alertStyle = {
  detailStyle: {
    padding: ".3rem",
    color: "white",
    borderRadius: "0px",
    marginBottom: "0",
    backgroundColor: "#008080",
    width: "150px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },

  entryInfo: {
    textAlign: "left",
    minWidth: "35%",
    color: "grey",
    fontSize: "16px",
  },
  numberPlateIcon: {
    fontSize: "22px",
    marginLeft: ".5rem",
    cursor: "pointer",
    color: "blue",
  },
  phoneNoIcon: {
    fontSize: "22px",
    marginLeft: ".5rem",
    cursor: "pointer",
    color: "blue",
  },
  borderStyle: {
    borderBottom: "1px solid gray",
    marginBottom: "1%",
    marginRight: "1rem",
  },
};

class VisitorDetails extends Component {
  render() {
    let { props } = this;
    let { entity } = props;
    let { numberPlateOnclick } = this.props;

    return (
      <>
        <div style={{ display: "flex", gap: "80px" }}>
          {entity?.visitor_type !== "employee" ?
            <Row style={{ width: "28%" }}>
              <Col xs={24} sm={24} md={24}>
                <>
                  <h2
                    style={{
                      marginBottom: "0%",
                      marginTop: "1%",
                      textAlign: "left",
                    }}
                  >
                    <p style={alertStyle.detailStyle}>Visitor Details</p>
                  </h2>
                  <div style={alertStyle.borderStyle}></div>
                </>

                {entity?.visitor.name && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Name
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor.name}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.visitor.fin_number && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        NFC/FIN
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor.fin_number}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.visitor.phone && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Phone
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor.phone}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.visitor.employer && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Employer
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity?.visitor.employer}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.purpose_of_visit && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Purpose of visit
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.purpose_of_visit}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.visitor_type && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Visit Type
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor_type}
                      </p>
                    </div>
                  </Row>
                )}
              </Col>
            </Row> :
            <Row style={{ width: "28%" }}>
              <Col xs={24} sm={24}>
                <>
                  <h2
                    style={{
                      marginBottom: "0%",
                      marginTop: "1%",
                      textAlign: "left",
                    }}
                  >
                    <p style={alertStyle.detailStyle}>Visitor Details</p>
                  </h2>
                  <div style={alertStyle.borderStyle}></div>
                </>

                {entity?.visitor.name && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Name
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor.name}
                      </p>
                    </div>
                  </Row>
                )}
                {entity?.visitor.phone && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Phone
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor.phone}
                      </p>
                    </div>
                  </Row>
                )}

                {entity?.visitor_type && (
                  <Row
                    xs={24}
                    sm={24}
                    md={24}
                    style={{ padding: "10px 0px 0px 10px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Visit Type
                        <span style={{ fontWeight: "bold" }}>:</span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.visitor_type}
                      </p>
                    </div>
                  </Row>
                )}
              </Col>
            </Row>
          }
          <Row>
            <Col xs={24} sm={24}>
              {(entity?.visitors?.length > 0 ||
                entity.mode_of_entry ||
                entity.number_plate ||
                entity.in_time) && (
                  <>
                    <h2
                      style={{
                        marginBottom: "0%",
                        marginTop: "1%",
                        textAlign: "left",
                      }}
                    >
                      <p style={alertStyle.detailStyle}>Entry Details</p>
                    </h2>
                    <div style={alertStyle.borderStyle}></div>
                  </>
                )}
              <Row
                xs={24}
                sm={24}
                md={12}
                style={{ padding: "0px 0px 0px 10px" }}
              >
                {entity.mode_of_entry && (
                  <div style={{ display: "flex" }}>
                    <h2 style={alertStyle.entryInfo}>
                      Mode of Entry
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "1.2rem",
                        }}
                      >
                        :
                      </span>
                    </h2>
                    <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                      {titleize(entity.mode_of_entry)}
                    </p>
                  </div>
                )}

                {entity?.number_plate && (
                  <Row>
                    <div style={{ display: "flex" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Number Plate
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: "1.3rem",
                          }}
                        >
                          :
                        </span>
                      </h2>
                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {entity.number_plate ? entity.number_plate : ""}
                      </p>
                      {entity?.number_plate && (
                        <IoMdOpen
                          onClick={numberPlateOnclick}
                          style={alertStyle.numberPlateIcon}
                        ></IoMdOpen>
                      )}
                    </div>
                  </Row>
                )}

                {entity && entity.in_time && (
                  <Row xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", color: "#393A3B" }}>
                      <h2 style={alertStyle.entryInfo}>
                        Entry Time
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: "2.6rem",
                          }}
                        >
                          :
                        </span>
                      </h2>

                      <p style={{ fontSize: "16px", fontWeight: "bolder" }}>
                        {moment(entity.in_time).format("MMM Do YYYY, h:mm:ss a")}
                      </p>
                    </div>
                  </Row>
                )}
              </Row>
            </Col>
          </Row>

        </div>
        <Row>
          {entity?.vehicle &&
            <Col xs={24} sm={12}>
              <VehicleDetails entity={entity} />
            </Col>
          }
        </Row>
      </>
    );
  }
}

export default VisitorDetails;
