import React from "react";
import { Col, Tabs } from "antd";
import MultiSelect from "components/inputs/Multiselect";
import SimpleTextInput from "components/inputs/simpleTextInput";
import SimplePhoneInput from "components/inputs/simplePhoneInput";

const users = ({
  first_name,
  last_name,
  email,
  phone,
  userRoles,
  botRoles,
  rolesData,
  handleChange,
  rolesBotData,
  type,
  country_code,
  rolesRequired,
}) => {
  const { TabPane } = Tabs;
  let roles_value = localStorage.getItem("roles");
  let removeRole = "Super Admin";
  let filtered_roles = rolesData?.filter((data) => data.label !== removeRole);

  return (
    <Tabs
      type="card"
      defaultActiveKey="User"
      activeKey={type}
      style={{ overflow: "visible" }}
      onChange={(value) => handleChange({ target: { value, name: "type" } })}
    >
      <TabPane tab="User" key="User">
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="First Name"
            name="first_name"
            required
            value={first_name}
            onChange={handleChange}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Last Name"
            name="last_name"
            value={last_name}
            onChange={handleChange}
          />
        </Col>

        <SimplePhoneInput
          countryValues={country_code}
          onChange={handleChange}
          labelText="Phone"
          name="phone"
          value={phone}
          length={country_code}
          required
        />
        <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
          <MultiSelect
            single={true}
            isMulti
            heading={`Roles *`}
            selected={userRoles}
            options={
              roles_value.includes("Super Admin") ? rolesData : filtered_roles
            }
            handleChange={handleChange}
            name="userRoles"
          />
        </Col>
        <React.Fragment>
          {rolesRequired && (
            <p style={{ color: "red", marginLeft: "14px", fontSize: "12px" }}>
              Roles cannot be blank
            </p>
          )}
        </React.Fragment>
      </TabPane>
      <TabPane tab="Bot" key="Bot">
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="First Name"
            name="first_name"
            required
            value={first_name}
            onChange={handleChange}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Last Name"
            name="last_name"
            value={last_name}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
          <MultiSelect
            single={true}
            isMulti
            heading={`Roles *`}
            selected={botRoles}
            options={rolesBotData}
            handleChange={handleChange}
            name="botRoles"
          />
        </Col>
        <React.Fragment>
          {rolesRequired && (
            <p style={{ color: "red", marginLeft: "14px", fontSize: "12px" }}>
              Roles cannot be blank
            </p>
          )}
        </React.Fragment>
      </TabPane>
    </Tabs>
  );
};
export default users;
